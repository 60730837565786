import React, { Suspense, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'

import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

import SettingDialog from '../components/SettingsDailog'

import LimitOrders from './Dashboard/limitOrders'

import { useIsDarkMode } from '../state/user/hooks'

export default function App() {
  const isDarkMode = useIsDarkMode()
  const [isSettingOpen, setIsSettingOpen] = useState(0)
  if (isDarkMode) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
  return (
    <Suspense fallback={null}>
      <Router>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />

        <div className="App">
          <div className="dark:bg-black dark:text-lightGray">
            {/* <div className="flex w-14.5 flex-col fixed inset-y-0 border-b-1 border-white">
              <div className="flex-1 flex flex-col min-h-0 dark:bg-black">
               
                <div className="flex-1 flex flex-col overflow-y-auto">
                  <SideNav isSettingOpen={isSettingOpen} setIsSettingOpen={setIsSettingOpen} />
                </div>
              </div>
            </div> */}
            <div className="flex flex-col ml-16">
              {/* <Header isSettingOpen={isSettingOpen} setIsSettingOpen={setIsSettingOpen} /> */}

              <main className="flex-1">
                <div className="max-w mx-auto">
                  <Web3ReactManager>
                    <Switch>
                      <Route exact strict path="" component={LimitOrders} />
                      {/* <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                  <Route exact strict path="/send" component={RedirectPathToSwapOnly} /> */}
                    </Switch>
                  </Web3ReactManager>
                </div>

                <SettingDialog isSettingOpen={isSettingOpen} setIsSettingOpen={setIsSettingOpen} />
              </main>
            </div>
          </div>
        </div>
      </Router>
    </Suspense>
  )
}
